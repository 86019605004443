import * as React from 'react'
import LocalBusinessSchemaHook from '../components/hooks/schema/localBusinessSchema'
import { StaticImage } from 'gatsby-plugin-image'
import { Link } from 'gatsby'
import styled from '@emotion/styled'

import Layout from '../components/layout'
import Seo from '../components/seo'

import GreenWrapper from '../components/wrappers/greenWrapper'
import GreyWrapper from '../components/wrappers/greyWrapper'
import SiteWidthWrapper from '../components/wrappers/siteWidthWrapper'
import Button from '../components/Button/button'
import CallNow from '../components/Button/call'

// images

import TrustedByOurClients from '../components/trusted'

const HeroText = styled.div`
  h1 {
    margin-top: 0;
  }
  h1,
  p {
    color: white;
    text-align: center;
  }

  // maintains adjusts font
  .stayCenter {
    margin-top: 40px;
    font-size: var(--fontSizeA);
    font-style: italic;
  }

  .HeroText_P {
    display: flex;
    max-width: var(--site-width);
    margin: 0 auto;
  }
`

const TwentyFourSeven = styled.div`
  p {
    font-weight: bold;
    color: var(--green);
    text-align: center;
  }
  .firstP {
    font-size: var(--fontSizeB);
    margin-bottom: 0;
    padding-bottom: 0;
  }
  .secondP {
    margin-top: 4px;
    padding-top: 4px;
  }
`

const ServiceDiv = styled.section`
  margin: var(--NoColorPadding);

  .wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 2em;

    @media (min-width: 1080px) {
      flex-direction: row;
      align-items: flex-start;
    }
  }

  .boxA,
  .boxB,
  .boxC {
    color: var(--green);
    font-weight: bold;
    font-size: var(--fontSizeA);
    width: 360px;
    height: 530px;
    margin-bottom: 32px;
    position: relative;
  }

  .servicesP {
    margin-top: 16px;
    line-height: 1.2;
    text-align: center;
  }
  .annual {
    font-weight: bold;
  }
`

const ServicesList = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  column-gap: 3em;
  color: var(--green)

  font-size: var(--fontSizeA);
  color: var(--green);
  font-weight: bold;
  text-align: center;

  a {
    color: var(--green);
    font-size: var(--fontSizeA);
    text-decoration-color: var(--blue);
  }

  @media (min-width: 768px) {
    flex-direction: row;
  }
`

const HereToHelp = styled.div`
  color: white;
  font-weight: bold;
  @media (min-width: 768px) {
    display: grid;
    grid-template-columns: 1fr 1fr;
    align-items: center;
  }

  .HereToHelp_boxA {
    font-size: var(--fontSizeA);
    font-weight: bold;

    .HereToHelp_boxA_P {
      text-align: center;
      line-height: 1.2;
      margin-bottom: 32px;

      @media (min-width: 768px) {
        margin-bottom: 0;
      }

      .HereToHelp_boxA_P_Span {
        color: var(--green);
      }
    }
  }
`

const TempStyle = {
  position: `absolute`,
  bottom: `0`,
  width: `100%`,
  textAlign: `center`,
}

const IndexPage = () => {
  return (
    <Layout>
      <Seo
        title="Ecotech Restorations Services"
        description="Vancouver&amp;s first choice for flood, fire, water, mold &amp; sewer backup damage restoration for the past 17 years."
        canonical="https://ecotechrestoration.ca"
      />

      <LocalBusinessSchemaHook />

      {/* Heros */}
      <div className="heroMobile">
        <StaticImage
          src="../images/heros/home.jpg"
          // maxHeight={600}
          quality={95}
          alt="Ecotech Restoration Home Splash"
        />
      </div>

      {/* Hero Text */}
      <GreenWrapper>
        <HeroText>
          <h1>GREATER VANCOUVER RESTORATION</h1>
          <div className="HeroText_P">
            <div>
              <p>
                We are Vancouver&apos;s first choice for Construction &amp;
                Restoration for the past 18 years. We specialize in flood, fire,
                water, mold and sewer back-up damage restoration.
              </p>
              <p className="stayCenter">We are on your side</p>
            </div>
          </div>
        </HeroText>
      </GreenWrapper>
      {/* ----- Heros END */}

      {/* 24/7 availability */}
      <SiteWidthWrapper>
        <TwentyFourSeven>
          <h2>We are available 24/7</h2>
          <p className="firstP">Do you have a restoration related emergency?</p>
          <p className="secondP">
            Call us now and we'll dispatch someone within the hour.
          </p>
          <div>
            {/* phone number div */}
            <CallNow />
            {/* phone number div end */}
          </div>
        </TwentyFourSeven>
      </SiteWidthWrapper>
      {/* ----- 24/7 availability END */}

      <ServiceDiv>
        <div className="wrapper">
          {/* image One */}
          <div className="boxA">
            <div>
              <StaticImage
                src="../images/home/service_flood.jpg"
                quality={100}
                alt="Water Damage Restoration Services"
              />
              <p className="servicesP">
                Water & Flood
                <br />
                Damage
              </p>
            </div>
            <Link
              style={TempStyle}
              to="/services/flood-damage-repair-and-restoration/"
              title="water and flood repair services">
              <Button
                label="Learn More about water and flood damage restoration"
                cta="Learn More"
              />
            </Link>
          </div>

          {/* image Two */}
          <div className="boxB">
            <div>
              <StaticImage
                src="../images/home/service_fire.jpg"
                //   width={300}
                quality={100}
                alt="fire damage restoration services"
              />
              <p className="servicesP">
                Fire Damage
                <br />
                Services
              </p>
            </div>
            <Link
              style={TempStyle}
              to="/services/fire-damage-repair-and-restoration/"
              title="fire damage repair services">
              <Button
                label="Learn More about fire damage remediation"
                cta="Learn More"
              />
            </Link>
          </div>

          {/* image Three */}
          <div className="boxC">
            <div>
              <StaticImage
                src="../images/home/service_mold.jpg"
                //   width={300}
                quality={100}
                alt="Mold damage Restoration Services"
              />
              <p className="servicesP">
                Mold
                <br />
                Restoration
              </p>
            </div>
            <Link
              style={TempStyle}
              to="/services/mold-damage-repair-and-restoration/"
              title="mold damage repair services">
              <Button
                label="Learn More about mold damage remediation"
                cta="Learn More"
              />
            </Link>
          </div>
        </div>
      </ServiceDiv>

      {/* List and GreyBar */}
      <GreyWrapper>
        <ServicesList>
          <div>
            <p>
              <Link
                to="/services/emergency-services/"
                title="Emergency Plumbing Services">
                Emergency Plumbing
              </Link>
            </p>
            <p>
              <Link
                to="/services/sewer-back-up-damage-repair-and-restoration/"
                title="Sewer Back-up Services">
                Sewer Back-up
              </Link>
            </p>
            <p>
              <Link
                to="/services/mold-damage-repair-and-restoration/"
                title="Mold Damage Restoration Services">
                Mold Damage
              </Link>
            </p>
          </div>
          <div>
            <p>
              <Link
                to="/services/flood-damage-repair-and-restoration/"
                title="Water and Flood Damage Restoration Services">
                Water &amp; Flood Damage
              </Link>
            </p>
            <p>
              <Link
                to="/services/fire-damage-repair-and-restoration/"
                title="Fire Damage Restoration Services">
                Fire Damage
              </Link>
            </p>
            <p>
              <Link
                to="/services/fire-damage-repair-and-restoration/"
                title="Smoke Damage Restoration Services">
                Smoke Damage
              </Link>
            </p>
          </div>
        </ServicesList>
      </GreyWrapper>
      {/* ----- List and GreyBar END */}

      {/* We are here to help */}
      <GreenWrapper>
        <HereToHelp>
          <div className="HereToHelp_boxA">
            <p className="HereToHelp_boxA_P">
              We Are Here
              <span className="HereToHelp_boxA_P_span">
                <br />
                to help you restore
                <br />
                your home or office
              </span>
            </p>
          </div>
          <div>
            <p>
              Our restoration division can handle any project that is thrown our
              way. We have a full team of technicians who are experienced and
              trained to handle any restoration need. We understand the value of
              immediate response, which is why we have dispatchers on hand 24
              hours a day, 7 days a week, every day of the year.
            </p>
          </div>
        </HereToHelp>
      </GreenWrapper>
      {/* ----- We are here to help and Green END */}

      {/* trusted by our clients */}
      <TrustedByOurClients />
      {/* ----- trusted by our clients END */}

      {/* company logos */}

      {/* ----- company logos END */}
    </Layout>
  )
}

export default IndexPage
