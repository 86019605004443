import * as React from 'react'
import SiteWidthWrapper from './wrappers/siteWidthWrapper'
import { trustedBy, firstP, boxWrapper } from './trusted.module.scss'
import { StaticImage } from 'gatsby-plugin-image'

const TrustedByOurClients = ({ data }) => {
  return (
    <SiteWidthWrapper>
      <div className={trustedBy}>
        <p className={firstP}>TRUSTED BY OUR CLIENTS</p>
        <p>
          We are the first choice for emergency restoration in British Columbia
          and trusted by our clients.
        </p>
      </div>

      <div className={boxWrapper}>
        <StaticImage
          src="../images/logos/apple_dental.png"
          width={174}
          height={86}
          quality={100}
          formats={['AUTO', 'WEBP']}
          alt="Apple Dental Logo"
        />
        <StaticImage
          src="../images/logos/associa.png"
          width={174}
          height={86}
          quality={100}
          formats={['AUTO', 'WEBP']}
          alt="associa logo"
        />
        <StaticImage
          src="../images/logos/coca_cola.png"
          width={174}
          height={86}
          quality={100}
          formats={['AUTO', 'WEBP']}
          alt="coca cola logo"
        />
        <StaticImage
          src="../images/logos/imi_tech.png"
          width={174}
          height={86}
          quality={100}
          formats={['AUTO', 'WEBP']}
          alt="imi tech logo"
        />
        <StaticImage
          src="../images/logos/pen_run.png"
          width={174}
          height={86}
          quality={100}
          formats={['AUTO', 'WEBP']}
          alt="pen run logo"
        />
        <StaticImage
          src="../images/logos/point_grey.png"
          width={174}
          height={86}
          quality={100}
          formats={['AUTO', 'WEBP']}
          alt="point grey logo"
        />
        <StaticImage
          src="../images/logos/protect_international.png"
          width={174}
          height={86}
          quality={100}
          formats={['AUTO', 'WEBP']}
          alt="protect international"
        />
        <StaticImage
          src="../images/logos/sunset_motel.png"
          width={174}
          height={86}
          quality={100}
          formats={['AUTO', 'WEBP']}
          alt="sunset motel logo"
        />
        <StaticImage
          src="../images/logos/taymor.png"
          width={174}
          height={86}
          quality={100}
          formats={['AUTO', 'WEBP']}
          alt="taymor logo"
        />
        <StaticImage
          src="../images/logos/wynford.png"
          width={174}
          height={86}
          quality={100}
          formats={['AUTO', 'WEBP']}
          alt="wynford"
        />
        <StaticImage
          src="../images/logos/canada_place.png"
          width={174}
          height={86}
          quality={100}
          formats={['AUTO', 'WEBP']}
          alt="taymor logo"
        />
        <StaticImage
          src="../images/logos/core_mark.png"
          width={174}
          height={86}
          quality={100}
          formats={['AUTO', 'WEBP']}
          alt="wynford"
        />
      </div>
    </SiteWidthWrapper>
  )
}

export default TrustedByOurClients

// const theImages = [
//   {
//     key: '1',
//     image: 'apple_dental',
//     alt: 'Apple Dental Logo',
//   },
//   {
//     key: '2',
//     image: 'associa',
//     alt: 'associa logo',
//   },
//   {
//     key: '3',
//     image: 'coca_cola',
//     alt: 'coca cola logo',
//   },
//   {
//     key: '4',
//     image: 'imi_tech',
//     alt: 'imi tech logo',
//   },
//   {
//     key: '5',
//     image: 'pen_run',
//     alt: 'pen run logo',
//   },
//   {
//     key: '6',
//     image: 'point_grey',
//     alt: 'point grey logo',
//   },
//   {
//     key: '7',
//     image: 'protect_international',
//     alt: 'protect international',
//   },
//   {
//     key: '8',
//     image: 'sunset_motel',
//     alt: 'sunset motel logo',
//   },
//   {
//     key: '9',
//     image: 'taymor',
//     alt: 'taymor logo',
//   },
//   {
//     key: '10',
//     image: 'wynford',
//     alt: 'wynford',
//   },
//   {
//     key: '11',
//     image: 'canada_place',
//     alt: 'canada place logo',
//   },
//   {
//     key: '12',
//     image: 'core_mark',
//     alt: 'core mark logo',
//   },
// ]
